<template>
    <div class="w-100 d-flex align-items-center justify-content-around" style="height:400px !important">
      <div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <feather-icon icon="PhoneCallIcon" size="30" class="text-primary" />
          <h3 class="mt-1">Phone</h3>
          <p>+212 716 927 327</p>
        </div>
      </div>
      <div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <feather-icon icon="MailIcon" size="30" class="text-primary" />
          <h3 class="mt-1">Mail Us</h3>
          <p>contact@applead.io</p>
        </div>
      </div>
      <div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <!-- <i class="ri-facebook-box-line text-primary" style="font-size:35px" ></i> -->
          <feather-icon icon="FacebookIcon" size="30" class="text-primary" />
          <h3 class="mt-1">Facebook Group</h3>
          <a href="https://www.facebook.com/groups/196329203367274" target="_blank">Visit Our Group</a>
        </div>
      </div>
    </div>
</template>
<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BContainer,
    BRow,
    BCol,
  },
};
</script>
